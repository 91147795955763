import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "../pages/common/home-page";
import LoginPage from "../pages/common/login-page";
import RegisterPage from "../pages/common/register-page";
import PrivacyPolicyPage from "../pages/common/privacy-policy-page";
import TermOfUsePage from "../pages/common/term-of-use-page";
import ProtectedRoute from "./protected-route";

const CustomRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route
            path="home"
            element={
              <ProtectedRoute>
                <Homepage />
              </ProtectedRoute>
            }
          />
          <Route path="home" element={<Homepage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="website-terms-of-use" element={<TermOfUsePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default CustomRoutes;
