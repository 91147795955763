import React from 'react'
import Home from '../../components/common/home/home'
import UserTemplate from '../../templates/user-template'

const Homepage = () => {
  return (
    <UserTemplate>
        <Home/>
    </UserTemplate>
  )
}

export default Homepage