import React from "react";
import CustomRoutes from "./router/custom-routes";

const App = () => {


  
  return (
    <>
      <CustomRoutes />
    </>
  );
};

export default App;
