import authHeader from "../helpers/functions/auth-header";
import axios from "axios";
import { settings } from "../helpers/settings";

const API_URL = settings.apiURL;

// Register
export const register = (user) => {
  return axios.post(`${API_URL}/register.php`, user); //test etmedim.
};

// Login
export const login = (credential) => {
  const jsonCredential = {};
  for (const [key, value] of credential.entries()) {
    jsonCredential[key] = value;
  }
  return axios.post(`${API_URL}/login.php`, jsonCredential);
};

// get getUser
export const getUser = () => {
  return axios.get(`${API_URL}/get_user.php`, { headers: authHeader() });
};

// put UpdateUser
// export const updateUser = (formData) => {
//   return axios.post(`${API_URL}/update_user.php`, formData, { headers: authHeader() });
// };

// patch updatePassword
// export const updatePassword = (formData) => {
//   return axios.post(`${API_URL}/update_password.php`, formData, {
//     headers: authHeader()
//   });
// };
