import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import avatar from "../../../assets/img/avatar/user-dummy-img.jpg";
import "./sidebar.scss";
import { question } from "../../../helpers/swal";
import { BiChevronLeft } from "react-icons/bi";
import { AiOutlineFileText, AiOutlineHome, AiOutlineSafetyCertificate } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { chevronClicked } from "../../../store/slices/sidebar-slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { logout } from "../../../store/slices/auth-slice";
import { encryptedLocalStorage } from "../../../helpers/functions/encrypt-storage";

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { sidebarLeft } = useAppSelector((state) => state.left);
  const dispatch = useAppDispatch();

  const handleSidebarToggle = () => {
    dispatch(chevronClicked(!sidebarLeft));
  };

  const handleLogout = () => {
    question("Logout", "Are you sure to logout?").then((result) => {
      if (result.isConfirmed) {
        dispatch(logout());
        encryptedLocalStorage.removeItem("token");
        localStorage.removeItem("first_name");
        localStorage.removeItem("last_name");
        sessionStorage.removeItem("googleApisToken");
        navigate("/");
      }
    });
  };

  const userName = localStorage.getItem("first_name") + " " + localStorage.getItem("last_name");

  return (
    <Navbar
      bg="light"
      expand="lg"
      className={sidebarLeft ? "admin-navbar admin-left" : "admin-navbar"}
      variant="dark"
    >
      <Container>
        <>
          <div className="avatar">
            <h5>MAYA UP</h5>
            <div className="admin-brand">
              <Navbar.Brand>
                <img src={avatar} alt="avatar" className="img-fluid" />
              </Navbar.Brand>
              <p>{userName}</p>
            </div>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                as={Link}
                to="/home"
                active={pathname === "/home"}
              >
                <AiOutlineHome /> Home
              </Nav.Link>
              {/* <Nav.Link
                as={Link}
                to="/"
                active={pathname.startsWith("/")}
              >
                <AiOutlineUser /> Profile
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to="/privacy-policy"
                active={pathname.startsWith("/privacy-policy")}
              >
                <AiOutlineSafetyCertificate /> Privacy Policy
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/website-terms-of-use"
                active={pathname.startsWith("/website-terms-of-use")}
              >
                <AiOutlineFileText /> Terms of Use
              </Nav.Link>
              
              <Nav.Link onClick={handleLogout}>
                Logout <FiLogOut />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </>
      </Container>
      <button
        className={`toggle-button ${sidebarLeft ? "active" : ""}`}
        onClick={handleSidebarToggle}
      >
        <div>
          <BiChevronLeft />
        </div>
      </button>
    </Navbar>
  );
};

export default Sidebar;
