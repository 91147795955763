import React from 'react'

const LoginTemplate = ({children}) => {
  return (
    <>
    {children}
    </>
  )
}

export default LoginTemplate