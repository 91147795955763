import React from 'react'
import TermOfUse from '../../components/common/term-of-use/term-of-use'
import UserTemplate from '../../templates/user-template'

const TermOfUsePage = () => {
  return (
    <UserTemplate>
        <TermOfUse/>
    </UserTemplate>
  )
}

export default TermOfUsePage