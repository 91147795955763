import axios from "axios";
import { settings } from "../helpers/settings";
import authGoogleTokenHeader from "../helpers/functions/auth-google-token-header";

const API_URL = settings.apiURL;
//const GOOGLE_TOKEN_ENDPOINT = "https://oauth2.googleapis.com/token";

// postGoogleApisToken
export const postGoogleApisToken = async () => {
  try {
    const respGoogleDriveToken = await axios.get(
      `${API_URL}/generateApiToken.php`
    );
    return respGoogleDriveToken.data.access_token;
  } catch (err) {
    throw err;
  }
};

// get getDrivePrintFiles
export const getDrivePrintFiles = () => {
  return axios.get(`${API_URL}/listNewPrintFilesFolder.php`, {
    headers: authGoogleTokenHeader(),
  });
};

// get getDriveSelectedDate
export const getDriveSelectedDate = (folderId) => {
  return axios.get(`${API_URL}/listSubFolders.php?folderId=${folderId}`, {
    headers: authGoogleTokenHeader(),
  });
};

// upload to server
// upload to drive
//check file

export const uploadFiles = async (
  folderId,
  filesWithHeight,
  user_id,
  folderName
) => {
  const promises = [];

  filesWithHeight.forEach(({ file, height }) => {
    const formData = new FormData();
    formData.append("dosyalar[]", file);
    formData.append("heights[]", height); // Yükseklik değerini FormData'ya ekleyin

    const promise = axios.post(
      `${API_URL}/uploadFiles.php?folderId=${folderId}&user_id=${user_id}&folderName=${folderName}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("googleApisToken")}`,
        },
      }
    );

    promises.push(promise);
  });

  try {
    const resp = await Promise.all(promises);
    return resp;
  } catch (error) {
    throw error;
  }
};

export const getUserFiles = (user_id) => {
  return axios.get(`${API_URL}/get_user_files.php?user_id=${user_id}`);
};

export const uploadToServer = (user_id, folderName, file) => {
  const formData = new FormData();
  formData.append("dosya", file);
  return axios.post(
    `${API_URL}/uploadToServer.php?user_id=${user_id}&folderName=${folderName}`,
    formData
  );
};

export const uploadToDrive = (temp_id, temp_path, folderId, folderName) => {
  return axios.post(
    `${API_URL}/uploadToDrive.php?temp_id=${temp_id}&folderName=${folderName}&folderId=${folderId}&temp_path=${temp_path}`,
    null, // Boş veri gönderiyoruz, çünkü bu bir POST isteği
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("googleApisToken")}`,
      },
    }
  );
};

export const checkFile = (temp_id, fileId, temp_path) => {
  return axios.get(
    `${API_URL}/checkFile.php?tempId=${temp_id}&fileId=${fileId}&temp_path=${temp_path}`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("googleApisToken")}`,
      },
    }
  );
};

export const deleteFile = (fileId) => {
  return axios.delete(`${API_URL}/deleteFile.php?fileId=${fileId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("googleApisToken")}`,
    },
  });
};
