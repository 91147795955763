import React from 'react'
import UserTemplate from '../../templates/user-template'
import PrivacyPolicy from '../../components/common/privacy-policy/privacy-policy'

const PrivacyPolicyPage = () => {
  return (
    <UserTemplate>
        <PrivacyPolicy/>
    </UserTemplate>
  )
}

export default PrivacyPolicyPage