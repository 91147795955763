import React from 'react'
import Login from '../../components/common/auth/login'
import LoginTemplate from '../../templates/login-template'

const LoginPage = () => {
  return (
    <LoginTemplate>
        <Login/>
    </LoginTemplate>
  )
}

export default LoginPage