import React, { useState } from "react";
import { Form, Button, InputGroup, Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { HiOutlineMail } from "react-icons/hi";
import { BsTelephone, BsCreditCard } from "react-icons/bs";
import * as Yup from "yup";
import PasswordInput from "./password-input";
import { toast } from "../../../helpers/swal";
import "./register.scss";
import { register } from "../../../api/user-service";

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function handleClick() {
    navigate("/");
  }

  const initialValues = {
    first_name: "",
    last_name: "",
    password: "",
    phone: "",
    email: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().min(2, "Please provide at least 2 characters").max(30, "Please provide at max 30 characters").required("Please enter your first name"),
    last_name: Yup.string().min(2, "Please provide at least 2 characters").max(30, "Please provide at max 30 characters").required("Please enter your last name"),
    email: Yup.string()
      .email("Plese enter a valid email address")
      .required("Please enter an email address"),
    password: Yup.string()
      .required("Please enter a password")
      .min(8, "Please provide at least 8 characters")
      .matches(/[a-z]+/, "One lowercase character")
      .matches(/[A-Z]+/, "One uppercase character")
      .matches(/[@$!%*#?&.]+/, "One special character")
      .matches(/\d+/, "One number"),
    confirmPassword: Yup.string()
      .required("Please re-enter your password")
      .oneOf([Yup.ref("password")], "Password fields dosen't match"),
    phone: Yup.string()
      .required("Please enter your phone number"),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await register(values);
      toast("You're registered", "success");
      formik.resetForm();
      navigate("/");
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Container fluid className="registerDiv">
      <Form noValidate onSubmit={formik.handleSubmit} className="p-4">
        <h2>MAYA UP</h2>
        <h3>SIGN UP</h3>
        <p>Fill in the boxes below to create your account</p>

        <InputGroup className="mt-2 mb-3">
          <InputGroup.Text>
            <BsCreditCard />
          </InputGroup.Text>
          <Form.Control
            className="email-input"
            placeholder="First Name"
            type="text"
            {...formik.getFieldProps("first_name")}
            isValid={formik.touched.first_name && !formik.errors.first_name}
            isInvalid={formik.touched.first_name && !!formik.errors.first_name}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.first_name}
          </Form.Control.Feedback>
        </InputGroup>

        <InputGroup className="mt-2 mb-3">
          <InputGroup.Text>
            <BsCreditCard />
          </InputGroup.Text>
          <Form.Control
            className="email-input"
            placeholder="Last Name"
            type="text"
            {...formik.getFieldProps("last_name")}
            isValid={formik.touched.last_name && !formik.errors.last_name}
            isInvalid={formik.touched.last_name && !!formik.errors.last_name}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.last_name}
          </Form.Control.Feedback>
        </InputGroup>

        <InputGroup className="mb-3">
          <InputGroup.Text>
            <BsTelephone />
          </InputGroup.Text>
          <Form.Control
          className="email-input"
            type="text"
            placeholder="Phone Number"
            {...formik.getFieldProps("phone")}
            isInvalid={formik.touched.phone && formik.errors.phone}
            isValid={formik.touched.phone && !formik.errors.phone}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.phone}
          </Form.Control.Feedback>
        </InputGroup>

        <InputGroup className="mb-3">
          <InputGroup.Text>
            <HiOutlineMail />
          </InputGroup.Text>
          <Form.Control
            className="email-input"
            type="email"
            placeholder="Email"
            {...formik.getFieldProps("email")}
            isInvalid={formik.touched.email && formik.errors.email}
            isValid={formik.touched.email && !formik.errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        </InputGroup>

        <PasswordInput
          placeholder="Password"
          {...formik.getFieldProps("password")}
          isInvalid={formik.touched.password && formik.errors.password}
          isValid={formik.touched.password && !formik.errors.password}
          error={formik.errors.password}
        />
        
        <PasswordInput
          placeholder="Confirm Password"
          {...formik.getFieldProps("confirmPassword")}
          isInvalid={formik.touched.confirmPassword && formik.errors.confirmPassword}
          isValid={formik.touched.confirmPassword && !formik.errors.confirmPassword}
          error={formik.errors.confirmPassword}
        />
        {/* <Form.Group
          className="mb-3 rememberForgot"
          controlId="formBasicCheckbox"
        >
          <Form.Check
            type="checkbox"
            label="Remember me"
            name="rememberMe"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <Link to="/forgot-password" style={{ textDecoration: "none" }}>
            <Form.Text className="forgotText">Forgot password?</Form.Text>
          </Link>
        </Form.Group> */}

        <Button
          variant="primary"
          type="submit"
          className="mb-2"
          disabled={!(formik.dirty && formik.isValid) || loading}
        >
          {loading && <Spinner animation="border" size="sm" />} SIGN UP
        </Button>

        <hr className="mt-1" />

        <Form.Group className="mb-2">
          <Form.Text>Do you have already an account?</Form.Text>
          <Form.Text onClick={handleClick}>
            <span style={{ cursor: "pointer", fontWeight: "bold"}}> &nbsp;Login Now!</span>
          </Form.Text>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default Register;
