import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Spinner,
  Image,
  ProgressBar,
  Table,
  Modal,
} from "react-bootstrap";
import {
  checkFile,
  deleteFile,
  getDrivePrintFiles,
  getDriveSelectedDate,
  getUserFiles,
  postGoogleApisToken,
  uploadFiles,
  uploadToDrive,
  uploadToServer,
} from "../../../api/drive-service";
import { settings } from "../../../helpers/settings";
import { toast } from "../../../helpers/swal";
import { getDateDayMountYear } from "../../../helpers/functions/date-time";
import "./home.scss";

const Home = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [listFolders, setListFolders] = useState([]);
  const [folderId, setFolderId] = useState("");
  const [fileLocation, setFileLocation] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState("");
  const [uploading, setUploading] = useState(false);

  const [userFiles, setUserFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const handleClose = () => {
    setShow(false);

    setProgress(0);
  };

  const handleShow = useCallback(() => {
    let currentProgress = 0;
    setShow(true);

    const increaseProgress = () => {
      const randomIncrement = Math.floor(Math.random() * 10) + 1;
      currentProgress = Math.min(currentProgress + randomIncrement, 100); // Maksimum değeri aşmaması için kontrol
      setProgress(currentProgress);

      if (currentProgress < 100) {
        setTimeout(increaseProgress, 500); // 500ms sonra increaseProgress fonksiyonunu tekrar çağır
      }
    };

    increaseProgress(); // İlk çağrı
  });

  const onDrop = (acceptedFiles) => {
    // Sadece .png uzantılı dosyaları kabul et
    const pngFiles = acceptedFiles.filter((file) => file.type === "image/png");
    if (pngFiles.length !== acceptedFiles.length) {
      toast("Only PNG files are allowed!", "warning");
      return;
    }

    // PNG dosyalarını işle
    setUploadedFiles(pngFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".png", // Sadece .png uzantılı dosyaları kabul et
  });

  // Dosyalar yüklendikten sonra, sayfa yenilendiğinde veya kullanıcı giriş yaptığında kullanıcı dosyalarını getir

  const getGoogleApisToken = async () => {
    try {
      const accessToken = await postGoogleApisToken();
      sessionStorage.setItem("googleApisToken", accessToken);
      // Token alındıktan sonra dosya listesini al
      await fetchDriveFiles();
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      // Hata durumunda kullanıcıya bilgi ver
      toast(message, "error");
    }
  };

  const fetchDriveFiles = async () => {
    try {
      const respDriveFiles = await getDrivePrintFiles();
      setListFolders(respDriveFiles.data);
      //  console.log(respDriveFiles);
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      // Hata durumunda kullanıcıya bilgi ver
      toast(message, "error");
    }
  };

  useEffect(() => {
    getGoogleApisToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (listFolders.length > 0) {
      handleDateSelection();
    }
    // eslint-disable-next-line
  }, [listFolders, selectedDate]);

  const handleDateSelection = () => {
    // console.log(selectedDate);
    const selectedFormattedDate = getDateDayMountYear(selectedDate);
    if (selectedFormattedDate) {
      const selectedFolder = listFolders.find(
        (folder) => folder.name === selectedFormattedDate
      );
      if (selectedFolder) {
        const selectedFolderId = selectedFolder.id;
        setFolderId(selectedFolderId);
        // console.log("Selected folder id:", selectedFolderId);
      } else {
        setFolderId("");
        setFileLocation([]);
      }
    } else {
      toast("No date selected", "warning");
    }
  };

  const getDriveSelectDate = async () => {
    // console.log(folderId);
    try {
      // Google Drive API Endpoint URL'i
      const resp = await getDriveSelectedDate(folderId);
      setFileLocation(resp.data);
      // console.log(resp.data);
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      // Hata durumunda kullanıcıya bilgi ver
      toast(message, "error");
    }
  };

  useEffect(() => {
    if (folderId) {
      getDriveSelectDate();
    }
    // eslint-disable-next-line
  }, [folderId]);

  // home.js

  const handleUpload = async () => {
    if (selectedLocation) {
      const selectedFolder = fileLocation.find(
        (folder) => folder.name === selectedLocation
      );

      if (selectedFolder) {
        const selectedFolderId = selectedFolder.id;
        setSelectedFileId(selectedFolderId);
        handleShow();
        setUploading(true); // Yükleme başladı
        try {
          const user_id = localStorage.getItem("user_id");

          // UPLOAD TO SERVER
          await Promise.all(
            uploadedFiles.map(async (file) => {
              try {
                const resp = await uploadToServer(
                  user_id,
                  selectedFolder.name,
                  file
                );

                const temp_path = resp.data.response.temp_path;
                const temp_id = resp.data.response.temp_id;

                // UPLOAD TO DRIVE
                const respDrive = await uploadToDrive(
                  temp_id,
                  temp_path,
                  selectedFolderId,
                  selectedFolder.name
                );

                const respCheck = await checkFile(
                  temp_id,
                  respDrive.data.file_id,
                  temp_path
                );

                toast(`${file.name} uploaded successfully!`, "success");
              } catch (error) {
                if (error.response && error.response.status === 400) {
                  toast(
                    `${file.name} could not be uploaded due to size constraints!`,
                    "warning"
                  );
                } else {
                  console.error("Sunucuya yükleme hatası:", error);
                  toast(
                    `Error uploading ${file.name}: ${error.message}`,
                    "error"
                  );
                }
              }
            })
          );

          setUploadedFiles([]);
          await get_user_files();
        } catch (err) {
          const message = err.response ? err.response.data.message : err;
          toast(message, "error");
        } finally {
          handleClose();
          setUploading(false); // Yükleme tamamlandı
        }
      } else {
        toast("No folder found for selected location", "warning");
      }
    } else {
      toast("No folder location selected", "warning");
    }
  };

  const get_user_files = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const user_files = await getUserFiles(user_id);
      setUserFiles(user_files.data);
    } catch (err) {
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    }
  };
  useEffect(() => {
    get_user_files(); // Sayfa yüklendiğinde kullanıcı dosyalarını güncelle
  }, []);

  const handleDelete = async (fileId, index) => {
    try {
      const response = await deleteFile(fileId);
      if (response.status === 200) {
        // Dosya başarıyla silindiğinde, deletedFiles dizisine index'i ekleyin
        setDeletedFiles([...deletedFiles, index]);

        // Dosya silindiğinde kullanıcı dosyalarını yeniden alarak liste güncellenir
        await get_user_files();

        // Silme işlemi başarılı toast mesajı göster
        toast("File deleted successfully!", "success");
      } else {
        console.error("Dosya silinirken bir hata oluştu.");
      }
    } catch (error) {
      console.error("Dosya silinirken bir hata oluştu.", error);
      // Silme işlemi sırasında hata oluşursa kullanıcıya bilgi ver
      toast("An error occurred while deleting the file.", "error");
    }
  };

  // Tarih seçim aralığı hesaplama
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 2);

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 2);

  useEffect(() => {
    // deletedFiles değiştiğinde (bir dosya silindiğinde), component yeniden render edilecek
  }, [deletedFiles]);

  return (
    <Container className="mayaup-home">
      <h3 className="mt-1 mb-0 text-center">MAYA UP</h3>

      <Row className="justify-content-center">
        <Col md={10}>
          <Card>
            <Card.Body>
              <Form className="p-4">
                <Form.Group controlId="formDate">
                  <Form.Label>Date Selection:</Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => {
                      setSelectedDate(e.target.value);
                    }}
                    value={selectedDate}
                    min={minDate.toISOString().split("T")[0]}
                    max={maxDate.toISOString().split("T")[0]}
                  />
                </Form.Group>

                <Form.Group controlId="formLocation">
                  <Form.Label>File Location Selection:</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    value={selectedLocation}
                  >
                    <option value="default">Please select a folder</option>
                    {fileLocation.map((location, index) => (
                      <option
                        key={index}
                        value={location.name}
                        style={{
                          fontSize: "14px",
                          maxHeight: "100px",
                          overflowY: "scroll",
                        }}
                      >
                        {location.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <div
                  {...getRootProps()}
                  style={dropzoneStyle}
                  className="mt-4 text-center"
                >
                  <input {...getInputProps()} />
                  <p>Upload files by drag and drop or click</p>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Yüklenen Dosyaların Listesi */}
      {uploadedFiles.length > 0 && (
        <Row className="mt-2 justify-content-center">
          <Col md={10}>
            <Card>
              <Card.Body className="p-4">
                <h3 className="text-center">Selected Files:</h3>

                <Row>
                  {uploadedFiles.map((file, index) => (
                    <Col key={index} xs={12} sm={6} md={4} lg={3}>
                      <div className="uploaded-file">
                        <div
                          className="delete-icon"
                          onClick={() => handleDelete(index)}
                        >
                          &times;
                        </div>
                        <Image src={URL.createObjectURL(file)} thumbnail />
                        <p className="text-center mt-2">
                          {file.name} <br></br>{" "}
                          {Math.round((file.size / 1024 / 1024) * 100) / 100} MB
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {/* Gönderme Butonu */}
      <Row className="mt-4 justify-content-center">
        <Col md={10} className="text-center">
          <Button variant="primary" onClick={handleUpload} disabled={uploading}>
            {uploading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="ml-2"> Uploading...</span>
              </>
            ) : (
              " Send Files"
            )}
          </Button>
        </Col>
      </Row>
      {/* Kullanıcının bugün yüklediği dosyaların listesi */}
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Body>
              <h3 className="text-center mb-4">Today's Uploaded Files</h3>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Uploaded Time</th>
                    <th>File Size</th>
                    <th>Folder Name</th>
                    <th>Roll Height</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userFiles.map((file, index) => (
                    <tr key={index}>
                      <td>{file.file_name}</td>
                      <td>{file.uploaded_time}</td>
                      <td>{file.file_size}</td>
                      <td>{file.folderName}</td>
                      <td>{file.inch_height + " inch"}</td>
                      <td
                        style={{
                          backgroundColor:
                            file.deleted == 1
                              ? "orange"
                              : file.success == 1
                              ? "lightgreen"
                              : "red",
                        }}
                      >
                        {file.deleted == 1
                          ? "DELETED"
                          : file.success == 1
                          ? "DONE"
                          : "ERROR"}
                      </td>
                      <td>
                        {file.deleted != 1 && file.success != "ERROR" && (
                          <button
                            onClick={() => handleDelete(file.file_id, index)}
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal centered size="lg" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Please Be Patience</Modal.Title>
        </Modal.Header>
        <Modal.Body>Files are uploading...</Modal.Body>
        <Modal.Body>
          <ProgressBar
            striped
            variant="warning"
            now={progress}
            label={`${progress}%`}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

const dropzoneStyle = {
  border: "2px dashed #cccccc",
  borderRadius: ".3rem",
  padding: "20px",
  cursor: "pointer",
};

export default Home;
