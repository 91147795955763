import React, { useState } from "react";
import { Form, Button, InputGroup, Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { HiOutlineMail } from "react-icons/hi";
import * as Yup from "yup";
import PasswordInput from "./password-input";
import { useAppDispatch } from "../../../store/hooks";
import { loginFailed, loginSuccess } from "../../../store/slices/auth-slice";
import { toast } from "../../../helpers/swal";
import { getUser, login } from "../../../api/user-service";
import { encryptedLocalStorage } from "../../../helpers/functions/encrypt-storage";
import "./login.scss";

const Login = () => {
  // const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  function handleClick() {
    navigate("/register");
  }

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter your email"),
    password: Yup.string().required("Please enter your password"),
  });
  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("password", values.password);

      const respAuth = await login(formData);
      encryptedLocalStorage.setItem("token", respAuth.data.token);
      const respUser = await getUser();
      dispatch(loginSuccess(respUser.data));
      localStorage.setItem("first_name", respUser.data.first_name);
      localStorage.setItem("last_name", respUser.data.last_name);
      localStorage.setItem("user_id", respUser.data.id);
      navigate("/home");
    } catch (err) {
      dispatch(loginFailed());
      const message = err.response ? err.response.data.message : err;
      toast(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Container fluid className="loginDiv">
      <Form noValidate onSubmit={formik.handleSubmit} className="p-4">
        <h2>MAYA UP</h2>
        <h3>SIGN IN</h3>
        <p>Enter your credentials to access your account</p>

        <InputGroup className="mt-2 mb-3">
          <InputGroup.Text>
            <HiOutlineMail />
          </InputGroup.Text>
          <Form.Control
            className="email-input"
            type="email"
            placeholder="Email"
            {...formik.getFieldProps("email")}
            isInvalid={formik.touched.email && formik.errors.email}
            isValid={formik.touched.email && !formik.errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        </InputGroup>

        <PasswordInput
          placeholder="Password"
          {...formik.getFieldProps("password")}
          isInvalid={formik.touched.password && formik.errors.password}
          isValid={formik.touched.password && !formik.errors.password}
          error={formik.errors.password}
        />

        {/* <Form.Group
          className="mb-3 rememberForgot"
          controlId="formBasicCheckbox"
        >
          <Form.Check
            type="checkbox"
            label="Remember me"
            name="rememberMe"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <Link to="/forgot-password" style={{ textDecoration: "none" }}>
            <Form.Text className="forgotText">Forgot password?</Form.Text>
          </Link>
        </Form.Group> */}

        <Button
          variant="primary"
          type="submit"
          className="mb-2"
          disabled={!(formik.dirty && formik.isValid) || loading}
        >
          {loading && <Spinner animation="border" size="sm" />} SIGN IN
        </Button>

        <hr className="mt-1" />

        <Form.Group className="mb-2">
          <Form.Text>Don't have an account?</Form.Text>
          <Form.Text onClick={handleClick}>
            <span style={{ cursor: "pointer", fontWeight: "bold" }}>
              {" "}
              &nbsp;Register Now!
            </span>
          </Form.Text>
        </Form.Group>

        <Button variant="light" type="submit" onClick={handleClick}>
          Register
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
