import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DashboardHeader from "../components/common/dashboard-header/dashboard-header.js";
import { useAppSelector } from "../store/hooks.js";
import Sidebar from "../components/common/sidebar/sidebar.js";
import "./user-template.scss";

const UserTemplate = ({ children }) => {
  const { sidebarLeft } = useAppSelector((state) => state.left);
  return (
    <Container fluid className="user-template">
      <Row>
        {sidebarLeft ? (
          <Col xs={0} lg={0} xxl={0} className="p-0">
            <Sidebar />
          </Col>
        ) : (
          <Col lg={3} xxl={2} className="p-0">
            <Sidebar />
          </Col>
        )}
        {sidebarLeft ? (
          <Col xs={12} lg={12} xxl={12} style={{ paddingLeft: "12px", zIndex: -1 }}>
            <DashboardHeader />
            {children}
          </Col>
        ) : (
          <Col lg={9} xxl={10} className="paddingLeft">
            <DashboardHeader />
            {children}
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default UserTemplate;
