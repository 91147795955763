import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const userName = localStorage.getItem("first_name");

  if (!userName) return <Navigate to="/" />;

  return children;
};

export default ProtectedRoute;
