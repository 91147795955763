import React from 'react'
import Register from '../../components/common/auth/register'
import LoginTemplate from '../../templates/login-template'

const RegisterPage = () => {
  return (
    <LoginTemplate>
        <Register/>
    </LoginTemplate>
  )
}

export default RegisterPage