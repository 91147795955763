const authGoogleTokenHeader = () => {
    const token = sessionStorage.getItem("googleApisToken");
  
    let header = {};
    if (token) {
      header = { Authorization: `Bearer ${token}` };
    }
  
    return header;
  };

export default authGoogleTokenHeader;